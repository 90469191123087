import React from "react";
import {
  Box,
  Tooltip,
  Grid,
  Typography,
  Paper,
  Button,
  Link,
  IconButton,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { green, red, teal } from "@material-ui/core/colors";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScrapeAccessCodeComponent from "./ScrapeAccessCodes";
import StartAutomationComponent from "./StartAutomation";
import AssignmentIcon from '@material-ui/icons/Assignment';

export default function Event(props) {
  let history = useHistory();
  const {
    name,
    id,
    url,
    created_at,
    deleteEvent,
    is_verified_fan_event,
    passed_count,
    access_code_received_count,
    is_automation_running,
    msg_content,
  } = props;

  return (
    <Box pt={2} pb={2}>
      <Paper elevation={3}>
        <Box p={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={1}
                justifyContent="center"
              >
                <Grid item>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Typography variant="h6"> {name} </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Link
                        href={url}
                        target="_blank"
                        rel="noopener"
                        variant="caption"
                      >
                        {url}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle2">
                    No. Of Registered Accounts :{" "}
                    <Tooltip title="No. Of Registered Accounts">
                      <Chip
                        label={passed_count}
                        size="small"
                        style={{
                          background: green[500],
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Grid>

                {/* {is_verified_fan_event && (
                  <Grid item>
                    <Typography variant="subtitle2">
                      No. Of Accounts Received Access Code : {" "}
                      <Tooltip title="No. Of Accounts Received Access Code">
                        <Chip
                          label={access_code_received_count}
                          size="small"
                          style={{
                            background: teal[500],
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                )} */}
              </Grid>
            </Grid>

            <Grid item>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Grid container spacing={1}>
                    {is_automation_running && (
                      <Grid item>
                        <Tooltip title="Automation is Running.">
                          <CircularProgress size="2rem" />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <Tooltip title="Assign Events">
                    <IconButton
                      onClick={() => {
                        history.push(`/event/${id}/assign-events`);
                      }}
                      size="large"
                    >
                      <AssignmentIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title="Select Accounts">
                    <IconButton
                      onClick={() => {
                        history.push(`/event/${id}/select-accounts`);
                      }}
                      size="large"
                    >
                      <GroupAddIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {/* 
                {is_verified_fan_event && (
                  <React.Fragment>
                    <Grid item>
                      <Tooltip title="Scrape Access Codes">
                        <ScrapeAccessCodeComponent
                          eventId={id}
                          eventName={name}
                          variant="small"
                          eventMsgContent={msg_content}
                        />
                      </Tooltip>
                    </Grid>
                    {!is_automation_running && (
                      <Grid item>
                        <Tooltip title="Start Automation">
                          <StartAutomationComponent
                            eventId={id}
                            eventName={name}
                            variant="small"
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                */}

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push(`/event/${id}/status`);
                    }}
                  >
                    Status
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Tooltip title={moment.utc(created_at).local().format("LLL")}>
                    <AccessTimeIcon />
                  </Tooltip>
                </Grid> */}
                <Grid item>
                  <Tooltip title="Delete Event">
                    <IconButton
                      onClick={() => {
                        deleteEvent(id);
                      }}
                      size="small"
                    >
                      <DeleteIcon style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
