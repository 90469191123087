import React, { useState } from "react";
import ImageIcon from "@material-ui/icons/Image";
import { IconButton, Tooltip } from "@material-ui/core";

import Lightbox from "react-image-lightbox";

export default function ScreenshotComponent(props) {
  const { imageUrl } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Screenshot">
        <IconButton
          onClick={() => {
            setIsOpen(true);
          }}
          size="medium"
        >
          <ImageIcon />
        </IconButton>
      </Tooltip>
      {isOpen && (
        <Lightbox mainSrc={imageUrl} onCloseRequest={() => setIsOpen(false)} />
      )}
    </React.Fragment>
  );
}
