import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { Tooltip, Box, Typography, Paper, Grid } from "@material-ui/core";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export default function NotificationsContainer() {
  const [notificationsData, setNotificationsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    getNotifications();
    axios.put("/notification/mark-as-read").then((response) => {
    });
  }, []);

  const getNotifications = () => {
    setLoading(true);
    axios.get("/notifications").then((response) => {
      setNotificationsData(response.data.items);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2} pb={3}>
          <Typography variant="h3">Notifications</Typography>
        </Box>

        {notificationsData.map((notification) => (
          <Paper variant="outlined" square>
            <Box p={2} key={notification.id}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h5"> {notification.text} </Typography>
                </Grid>
                <Grid item>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Tooltip
                        title={moment
                          .utc(notification.created_at)
                          .local()
                          .format("LLL")}
                      >
                        <AccessTimeIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography variant="body">
                        {moment.utc(notification.created_at).local().fromNow()}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item>
                  <Tooltip title="Delete Tag">
                    <IconButton
                      onClick={() => {
                        deleteTag(tag.id);
                      }}
                      size="small"
                    >
                      <DeleteIcon style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </Grid> */}
              </Grid>
            </Box>
          </Paper>
        ))}

        {notificationsData.length === 0 && (
          <Box pt={2}>
            <Typography variant="h5" align="center">
              No Notifications.
            </Typography>
          </Box>
        )}
      </Layout>
    </React.Fragment>
  );
}
