import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import QueueIcon from "@material-ui/icons/Queue";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DataGrid } from "@material-ui/data-grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Chip,
  Tooltip,
  Button,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import { CSVLink } from "react-csv";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import teal from "@material-ui/core/colors/teal";
import ScreenshotComponent from "../components/Screenshot";
import ScrapeAccessCodeComponent from "../components/ScrapeAccessCodes";
import StartAutomationComponent from "../components/StartAutomation";
import { Clear } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));


export default function EventStatusContainer() {
  const [eventData, setEventData] = useState();
  const [accountsData, setAccountsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState();
  const [filterValue, setFilterValue] = useState("FAILED");
  const { event_id } = useParams();
  const [message, setMessage] = useState();
  const classes = useStyles();
  const GREEN = "#4caf50a3";
  const RED = "#ff0000ab";
  const YELLOW = "#ffc107bd";
  const BLUE = "#2196f3a6";
  const GREY = "#9e9e9e";

  useEffect(() => {
    getSummaryData();

    axios.get(`/event/${event_id}`).then((response) => {
      setEventData(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id]);

  useEffect(() => {
    refreshAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id, filterValue]);

  const getSummaryData = () => {
    axios.get(`/automation/event/${event_id}/summary`).then((response) => {
      setSummaryData(response.data);
    });
  };
  const refreshAccounts = () => {
    setLoading(true);
    axios
      .get(`/automation/event/${event_id}/accounts`, {
        params: {
          status: filterValue,
        },
      })
      .then((response) => {
        setAccountsData(response.data);
        setLoading(false);
      });
  };
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const refreshData = () => {
    getSummaryData();
    refreshAccounts();
  };

  const clearErrors = () => {
    if (window.confirm("Are you sure you want to clear all the errors ?")) {
      setLoading(true);
      axios.get(`/automation/event/${event_id}/clear-errors`).then((response) => {
        setLoading(false);
        setMessage({ type: "success", text: response.data });
        refreshData();
      });
    }
  };

  const exportDataLink = useRef();

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => params.row.account.email,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 160,
      valueGetter: (params) => params.row.account.first_name,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      valueGetter: (params) => params.row.account.phone_number,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      valueGetter: (params) => params.row.trial_count,
      renderCell: (params) =>
        params.row.status && (
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                label={
                  params.row.status === "PASSED"
                    ? "Passed"
                    : params.row.status === "FAILED"
                    ? "Failed"
                    : params.row.status === "IN_PROGRESS"
                    ? "In Progress"
                    : params.row.status === "IN_QUEUE"
                    ? "In Queue"
                    : params.row.status === "SKIPPED"
                    ? "Skipped"
                    : ""
                }
                size="small"
                style={{
                  background:
                    params.row.status === "PASSED"
                      ? GREEN
                      : params.row.status === "FAILED"
                      ? RED
                      : params.row.status === "IN_PROGRESS"
                      ? YELLOW
                      : params.row.status === "IN_QUEUE"
                      ? BLUE
                      : params.row.status === "SKIPPED"
                      ? GREY
                      : "",
                  color: params.row.status === "FAILED" ? "white" : "",
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip title="Trial Count">
                <Chip
                  label={params.row.trial_count}
                  size="small"
                  color="primary"
                />
              </Tooltip>
            </Grid>
            {params.row.screenshot_url && (
              <Grid item>
                <ScreenshotComponent imageUrl={params.row.screenshot_url} />
              </Grid>
            )}
          </Grid>
        ),
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 150,
      renderCell: (params) =>
        params.row.registration_completed_at &&
        params.row.registration_started_at && (
          <Chip
            label={moment
              .utc(
                moment(params.row.registration_completed_at).diff(
                  moment(params.row.registration_started_at)
                )
              )
              .format("HH [h] mm [m] ss [s]")}
            size="small"
            color="primary"
          />
        ),
      valueGetter: (params) =>
        params.row.registration_completed_at &&
        params.row.registration_started_at &&
        moment(params.row.registration_completed_at).diff(
          moment(params.row.registration_started_at)
        ),
    },
    {
      field: "registration_completed_at",
      headerName: "Finished At",
      width: 200,
      valueGetter: (params) =>
        params.row.registration_completed_at &&
        parseInt(moment(params.row.registration_completed_at).format("x")),
      renderCell: (params) =>
        params.row.registration_completed_at && (
          <Typography>
            {moment
              .utc(params.row.registration_completed_at)
              .local()
              .format("lll")}
          </Typography>
        ),
    },
    { field: "access_code", headerName: "Access Code", width: 170 },
    { field: "error", headerName: "Error", minWidth: 300, flex: 1 },
    {
      field: "tm_event_ids",
      headerName: "Event Ids",
      minWidth: 250,
      flex: 1,
    },
  ];

  const export_headers = [
    { label: "Email", key: "account.email" },
    { label: "First Name", key: "account.first_name" },
    { label: "Last Name", key: "account.last_name" },
    { label: "Password", key: "account.password" },
    { label: "Phone Number", key: "account.phone_number" },
    { label: "Status", key: "status" },
    { label: "Trial Count", key: "trial_count" },
    { label: "Registration Started", key: "registration_started_at" },
    { label: "Registration Completed", key: "registration_completed_at" },
    { label: "Access Code", key: "access_code" },
    { label: "Error", key: "error" },
    { label: "Screenshot", key: "screenshot_url" },
  ];

 
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2}>
          {eventData && (
            <Typography variant="h4">
              Event {eventData.name} Status
              <Tooltip title="Refresh Data">
                <IconButton
                  onClick={() => {
                    refreshData();
                  }}
                  size="medium"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
        </Box>
        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}
        {summaryData && (
          <Box pt={2} pb={1}>

           <Grid container>
            <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table >
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.head}>Passed</TableCell>
                    <TableCell style={{ background: GREEN }}>  {summaryData.PASSED ? summaryData.PASSED : 0}</TableCell>
                    <TableCell className={classes.head}>Failed</TableCell>
                    <TableCell style={{ background: RED }}>   {summaryData.FAILED ? summaryData.FAILED : 0}</TableCell>
                  </TableRow>

                

                  <TableRow>
                    <TableCell className={classes.head}>In Progress</TableCell>
                    <TableCell style={{ background: YELLOW }}>   {summaryData.IN_PROGRESS
                                ? summaryData.IN_PROGRESS
                                : 0}</TableCell>
                                 <TableCell className={classes.head}>In Queue</TableCell>
                    <TableCell style={{ background: BLUE }}>   {summaryData.IN_QUEUE ? summaryData.IN_QUEUE : 0}</TableCell>
                  </TableRow>

                  
                  <TableRow>
                    <TableCell className={classes.head}>Skipped</TableCell>
                    <TableCell style={{ background: GREY }}>   {summaryData.SKIPPED ? summaryData.SKIPPED : 0}</TableCell>
                    <TableCell className={classes.head}>Access Codes</TableCell>
                    <TableCell style={{ background: teal[500] }}>   {summaryData.access_code_count
                                    ? summaryData.access_code_count
                                    : 0}</TableCell>
                  </TableRow>

                  
                </TableBody>
              </Table>
            </TableContainer>

            </Grid>
           </Grid>

            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-around" spacing={2}>
                  <Grid item>
                    <Card style={{ background: GREEN }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <CheckCircleOutlineIcon fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" component="h2">
                              {summaryData.PASSED ? summaryData.PASSED : 0}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography variant="h5" component="h2">
                          Passed
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item>
                    <Card style={{ background: RED }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <CancelIcon fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" component="h2">
                              {summaryData.FAILED ? summaryData.FAILED : 0}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography variant="h5" component="h2">
                          Failed
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card style={{ background: YELLOW }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <TimelapseIcon fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" component="h2">
                              {summaryData.IN_PROGRESS
                                ? summaryData.IN_PROGRESS
                                : 0}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography variant="h5" component="h2">
                          In Progress
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item>
                    <Card style={{ background: BLUE }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <QueueIcon fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" component="h2">
                              {summaryData.IN_QUEUE ? summaryData.IN_QUEUE : 0}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography variant="h5" component="h2">
                          In Queue
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item>
                    <Card style={{ background: GREY }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <RemoveCircleOutlineIcon fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" component="h2">
                              {summaryData.SKIPPED ? summaryData.SKIPPED : 0}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography variant="h5" component="h2">
                          Skipped
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  {eventData && eventData.is_verified_fan_event && (
                    <Grid item>
                      <Card style={{ background: teal[500] }}>
                        <CardContent>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <ThumbUpIcon fontSize="large" />
                            </Grid>
                            <Grid item>
                              <Tooltip title="Number of Accounts that Received Access Code">
                                <Typography variant="h5" component="h2">
                                  {summaryData.access_code_count
                                    ? summaryData.access_code_count
                                    : 0}
                                </Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>

                          <Typography variant="h5" component="h2">
                            Access Code{" "}
                            {eventData && eventData.msg_content && (
                              <Tooltip
                                title={`Accees code extracted with message containing text : ${eventData.msg_content}`}
                              >
                                <InfoOutlinedIcon fontSize="small" />
                              </Tooltip>
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid> */}
          </Box>
        )}

        <Box pt={1}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Filter</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={filterValue}
                  onChange={handleFilterChange}
                  row
                >
                  <FormControlLabel value="" control={<Radio />} label="All" />
                  <FormControlLabel
                    value="PASSED"
                    control={<Radio />}
                    label="Passed"
                  />
                  <FormControlLabel
                    value="FAILED"
                    control={<Radio />}
                    label="Failed"
                  />
                  <FormControlLabel
                    value="IN_PROGRESS"
                    control={<Radio />}
                    label="In Progress"
                  />
                  <FormControlLabel
                    value="IN_QUEUE"
                    control={<Radio />}
                    label="In Queue"
                  />
                  <FormControlLabel
                    value="SKIPPED"
                    control={<Radio />}
                    label="Skipped"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <Grid item container spacing={1}>
                {eventData && eventData.is_verified_fan_event && (
                  <React.Fragment>
                    <Grid item>
                      <ScrapeAccessCodeComponent
                        eventId={eventData.id}
                        eventName={eventData.name}
                        eventMsgContent={eventData.msg_content}
                      />
                    </Grid>

                    {summaryData && summaryData.IN_PROGRESS === undefined && (
                      <Grid item>
                        <StartAutomationComponent
                          eventId={eventData.id}
                          eventName={eventData.name}
                          eventMsgContent={eventData.msg_content}
                        />
                      </Grid>
                    )}



                  </React.Fragment>
                )}

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => {
                      exportDataLink.current.link.click();
                    }}
                  >
                    Export
                  </Button>
                  <CSVLink
                    data={accountsData}
                    headers={export_headers}
                    filename={
                      eventData &&
                      `${eventData.name}_${moment().format(
                        "YYYYMMDD_hmmss"
                      )}.csv`
                    }
                    ref={exportDataLink}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    style={{
                      color: RED,
                      borderColor: RED,
                    }}

                    startIcon={<Clear />}
                    onClick={clearErrors}
                  >
                    Clear Errors
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <DataGrid
            rows={accountsData}
            columns={columns}
            // onSelectionModelChange={(itm) => setSelectedRows(itm.selectionModel)}
            disableSelectionOnClick
            // selectionModel={selectionModel}
            autoHeight
          />
        </Box>
      </Layout>
    </React.Fragment>
  );
}
