import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Layout from "./Layout";
import Event from "../components/Event";
import Loader from "../components/Loader";
import { useHistory } from "react-router-dom";

export default function EventsContainer() {
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [eventName, setEventName] = useState();
  const [eventURL, setEventURL] = useState();
  const [message, setMessage] = useState();
  const [isVerifiedFanEvent, setIsVerifiedFanEvent] = useState(true);

  let history = useHistory();
  useEffect(() => {
    refreshEvents();
  }, []);

  const refreshEvents = () => {
    setLoading(true);
    axios.get("/events").then((response) => {
      setEvents(response.data);
      setLoading(false);
    });
  };
  const createEvent = () => {
    setLoading(true);
    axios
      .post("/event/", {
        name: eventName,
        url: eventURL,
        is_verified_fan_event: isVerifiedFanEvent,
      })
      .then((response) => {
        setLoading(false);
        setMessage({ type: "success", text: "Event Created Successfully." });
        refreshEvents();
      });
  };
  const createEventWithAllActiveAccountsAssigned = () => {
    setLoading(true);
    axios
      .post("/event/", {
        name: eventName,
        url: eventURL,
        is_verified_fan_event: isVerifiedFanEvent,
      })
      .then((response) => {
        setLoading(false);
        setMessage({ type: "success", text: "Event Created Successfully." });
        refreshEvents();
        addAllActiveAccounts(response.data.id);
      });
  };

  const addAllActiveAccounts = (event_id) => {
    setLoading(true);
    axios
      .post(`/automation/event/${event_id}/add-all-active-accounts`)
      .then((response) => {
        setLoading(false);
        history.push(`/event/${event_id}/status`);
      });
  };
  const deleteEvent = (id) => {
    if (window.confirm("Are you sure you want to delete this event ?")) {
      setLoading(true);
      axios.delete(`/event/${id}`).then((response) => {
        setLoading(false);
        setMessage({ type: "success", text: "Event Deleted Successfully." });
        refreshEvents();
      });
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Typography variant="h3">Events</Typography>

        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}
        <Paper elevation={3}>
          <Box p={5}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} md={2} lg={2} xl={2}>
            <TextField
              id="event_name"
              label="Event Name"
              // variant="outlined"
              fullWidth
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={5}>
            <TextField
              id="event_url"
              label="Event URL"
              // variant="outlined"
              fullWidth
              value={eventURL}
              onChange={(e) => setEventURL(e.target.value)}
              size="small"
            />
          </Grid>
          {/* <Grid item xs={12} md={3} lg={2} xl={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isVerifiedFanEvent}
                  onChange={(e) => {
                    setIsVerifiedFanEvent(e.target.checked);
                  }}
                  name="isVerifiedFanEvent"
                />
              }
              label="Verified Fan Event"
            />
          </Grid> */}
          {/* <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={createEvent}
              disabled={
                eventURL === undefined ||
                eventURL === "" ||
                eventName === undefined ||
                eventName === ""
              }
            >
              Create
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={createEventWithAllActiveAccountsAssigned}
              disabled={
                eventURL === undefined ||
                eventURL === "" ||
                eventName === undefined ||
                eventName === ""
              }
            >
              Create
            </Button>
          </Grid>
        </Grid>
        </Box>
</Paper>
        {events &&
          events.map((event) => {
            return (
              <Event
                key={event.id}
                {...event}
                deleteEvent={deleteEvent}
              />
            );
          })}
       
        {events.length === 0 && (
          <Box pt={2}>
            <Typography variant="h5" align="center">
              No events.
            </Typography>
          </Box>
        )}
      </Layout>
    </React.Fragment>
  );
}
