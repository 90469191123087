import { green, lightGreen, orange, red } from "@material-ui/core/colors";
import { Chip, Grid, Tooltip } from "@material-ui/core";
const columns = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "email", headerName: "Email", width: 250 },
  { field: "first_name", headerName: "First Name", width: 170 },
  { field: "last_name", headerName: "Last Name", width: 170 },
  { field: "password", headerName: "Password", width: 160 },
  { field: "phone_number", headerName: "Phone Number", width: 200 },
  { field: "zip_code", headerName: "Zip Code", width: 150 },
  { field: "address_1", headerName: "Proxy", width: 350 },
  {
    field: "is_active",
    headerName: "Status",
    width: 160,
    renderCell: (params) => (
      <Chip
        label={params.row.is_active ? "Active" : "Disabled"}
        size="small"
        color={params.row.is_active ? "secondary" : ""}
      />
    ),
  },
   { field: "additional_field_1", headerName: "Additonal Field 1", width: 350 },
   { field: "additional_field_2", headerName: "Additonal Field 2", width: 350 },
   { field: "additional_field_3", headerName: "Additonal Field 3", width: 350 },
];

export default columns;
