import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import { useParams } from "react-router-dom";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import AccountColumns from "../utils/accounts_grid_column";

export default function EventSelectAccountContainer() {
  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState();
  const [eventData, setEventData] = useState();
  const { event_id } = useParams();
  const [selectionModel, setSelectionModel] = useState([]);

  let history = useHistory();
  useEffect(() => {
    axios.get(`/event/${event_id}`).then((response) => {
      setEventData(response.data);
    });

    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id]);

  const getAccounts = () => {
    setLoading(true);
    axios
      .get(`/automation/event/${event_id}/available-accounts`)
      .then((response) => {
        setAccounts(response.data);
        setLoading(false);
      });
  };
  const addAllActiveAccounts = () => {
    setLoading(true);
    axios
      .post(`/automation/event/${event_id}/add-all-active-accounts`)
      .then((response) => {
        setLoading(false);
        history.push(`/event/${event_id}/status`);
      });
  };

  const addSelectedAccounts = () => {
    setLoading(true);
    axios
      .post(`/automation/event/${event_id}/add-accounts`, {
        account_ids: selectionModel,
      })
      .then((response) => {
        setLoading(false);
        history.push(`/event/${event_id}/status`);
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2}>
          {eventData && (
            <Typography variant="h4">
              Select Accounts For {eventData.name}
            </Typography>
          )}
        </Box>

        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}

        <Box pt={2}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={addSelectedAccounts}
                disabled={selectionModel.length === 0}
              >
                Add Selected Accounts
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SelectAllIcon />}
                onClick={addAllActiveAccounts}
              >
                Add All Active Accounts
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <DataGrid
            rows={accounts}
            columns={AccountColumns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) =>
              setSelectionModel(newSelectionModel)
            }
            disableSelectionOnClick
            selectionModel={selectionModel}
            autoHeight
          />
        </Box>
      </Layout>
    </React.Fragment>
  );
}
