import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import { teal } from "@material-ui/core/colors";
import { Box, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { Alert } from "@material-ui/lab";

export default function ScrapeAccessCodeComponent(props) {
  const { eventId, eventName, variant, eventMsgContent } = props;
  const [open, setOpen] = useState(false);
  const [messageContent, setMessageContent] = useState(eventMsgContent);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const scrape = () => {
    console.log(eventId);
    console.log(messageContent);
    setLoading(true);
    let params = {};
    if (messageContent !== null && messageContent.trim() !== "") {
      params.msg_content = messageContent;
    }

    axios
      .get(`/event/${eventId}/scrape-access-codes`, {
        params: params,
      })
      .then((response) => {
        setMessage({ type: "success", text: response.data.message });
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {variant === "large" ? (
        <Button variant="outlined" color="primary" onClick={handleClickOpen} startIcon={<KeyboardIcon />}>
          Scrape Access Codes
        </Button>
      ) : (
        <IconButton onClick={handleClickOpen} size="small">
          <KeyboardIcon style={{ color: teal[500] }} />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Scrape Access Codes for {eventName}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          <DialogContentText>
            To extract the access codes from Message Infrastucture.By default it
            extracts the access code within last 24 hours with message
            containing 'access codes' in it. If there are multile access codes
            received at the same time then provide the unique message containing
            string below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Message Content"
            fullWidth
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={scrape}
            color="primary"
            disabled={loading}
          >
            Scrape
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ScrapeAccessCodeComponent.propTypes = {
  eventId: PropTypes.any.isRequired,
  eventName: PropTypes.any.isRequired,
  eventMsgContent: PropTypes.any.isRequired,
  variant: PropTypes.any.isRequired,
};

ScrapeAccessCodeComponent.defaultProps = {
  variant: "large",
  eventMsgContent: "",
};
