import React, { useState, useEffect,useRef } from "react";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import { CSVReader } from "react-papaparse";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { CSVLink } from "react-csv";

export default function AssignEventsContainer() {
  const [isLoading, setLoading] = useState(false);
  const exportTemplateLink = useRef();
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState();
  const [eventData, setEventData] = useState();
  const { event_id } = useParams();
  const [eventAssignData,setEventAssignData ] = useState([]);
  const export_headers = [
    { label: "email", key: "email" },
    { label: "event_1", key: "tag" },
    { label: "event_2", key: "tag" },
    { label: "event_3", key: "tag" },
    { label: "event_4", key: "tag" },
    { label: "event_5", key: "tag" },
    { label: "event_6", key: "tag" },
    { label: "event_7", key: "tag" },
    { label: "event_8", key: "tag" },
    { label: "event_9", key: "tag" },
    { label: "event_10", key: "tag" },

  ];
  useEffect(() => {
    axios.get(`/event/${event_id}`).then((response) => {
      setEventData(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id]);

  const handleOnDrop = (records) => {
    let assignTagData = [];
    for (let record of records) {
      let email_record = { email : record.data.email,event_ids:[] }
      for (let i = 1; i < 11; i++) {
        let event_id = record.data[`event_${i}`].trim();
        if (event_id !== ''){
          email_record.event_ids.push(event_id);
        }
      }
      assignTagData.push(email_record);
    }
    console.log(assignTagData);
    setEventAssignData(assignTagData);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const upload = () => {
    setLoading(true);
    axios
      .post(`/automation/event/${event_id}/assign-events`, eventAssignData)
      .then((response) => {
        setMessage({ type: "success", text: response.data });
        setLoading(false);
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.statusText });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2}>
          {eventData && (
            <Typography variant="h4">
              Assign Ticket Master events for {eventData.name}
            </Typography>
          )}
        </Box>

        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}

        <Box pt={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                    config={{ header: true, skipEmptyLines: true }}
                    style={{
                      dropArea: {
                        padding: "15px",
                      },
                      removeButton: {
                        color: "red",
                      },
                    }}
                  >
                    <span>
                      Drop Assign Events Upload CSV file here or click to upload.
                    </span>
                  </CSVReader>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PublishIcon />}
                    onClick={upload}
                    disabled={eventAssignData.length === 0}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => {
                      exportTemplateLink.current.link.click();
                    }}
                  >
                    Bulk Event Assign Template
                  </Button>
                  <CSVLink
                    data={[]}
                    headers={export_headers}
                    filename={`tm_vf_assign_event_template.csv`}
                    ref={exportTemplateLink}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


      </Layout>
    </React.Fragment>
  );
}
