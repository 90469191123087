import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Badge,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PeopleIcon from "@material-ui/icons/People";
import EventIcon from "@material-ui/icons/Event";
import LabelIcon from "@material-ui/icons/Label";
import NotificationsIcon from "@material-ui/icons/Notifications";

const CustomNavButton = (props) => {
  let history = useHistory();
  return (
    <Button
      {...props}
      component={Link}
      variant={
        history.location.pathname === props.to ? "contained" : "outlined"
      }
      color="secondary"
    >
      {props.children}
    </Button>
  );
};

export default function Header() {
  let history = useHistory();
  const [userData, setUserData] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);

  const getNotifications = () => {
    axios
      .get("/notifications", { params: { is_read: false } })
      .then((response) => {
        console.log(response.data);
        setNotificationsCount(response.data.total);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`; // for all requests
    }
    axios
      .get("/user/me")
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          history.push("/");
        }
      });
    getNotifications();
    // const interval = setInterval(getNotifications, 3000);

    // return () => {
    //   console.log("cleaning up");
    //   clearInterval(interval);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar position="static">
      <Box pb={2}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
           
            <Grid item>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                alignItems="center"
              >
                {/* <Grid item>
                  <Tooltip title="Notifications">
                    <IconButton
                      onClick={() => {
                        history.push("/notifications");
                      }}
                      size="medium"
                    >
                      <Badge
                        color="secondary"
                        badgeContent={notificationsCount}
                      >
                        <NotificationsIcon color="secondary" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid> */}
                <Grid item>
                  <CustomNavButton startIcon={<EventIcon />} to="/events">
                    Events
                  </CustomNavButton>
                </Grid>
                <Grid item>
                  <CustomNavButton startIcon={<PeopleIcon />} to="/accounts">
                    Accounts
                  </CustomNavButton>
                </Grid>
                {/* <Grid item>
                  <CustomNavButton startIcon={<LabelIcon />} to="/tags">
                    Tags
                  </CustomNavButton>
                </Grid> */}

                <Grid item>
                  <CustomNavButton startIcon={<AccountCircle />} to="/profile">
                    {userData && userData.full_name}
                  </CustomNavButton>
                </Grid>
                <Grid item>
                  <Box mr={1}>
                    <CustomNavButton
                      startIcon={<ExitToAppIcon />}
                      onClick={() => {
                        localStorage.clear();
                        history.push("/");
                      }}
                    >
                      Logout
                    </CustomNavButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
