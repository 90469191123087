import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import { Box, IconButton, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import SendIcon from '@material-ui/icons/Send';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function StartAutomationComponent(props) {
  const { eventId, eventName, variant } = props;
  const [open, setOpen] = useState(false);
  const [noOfThreads, setNoOfThreads] = useState(16);
  const [mode, setMode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const startAutomation = () => {
    setLoading(true);
    let params = { no_of_threads: noOfThreads, mode };
    axios
      .get(`/event/${eventId}/run-automation`, {
        params: params,
      })
      .then((response) => {
        setMessage({ type: "success", text: response.data.message });
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {variant === "large" ? (
        <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<SendIcon />}>
          Start Automation
        </Button>
      ) : (
        <IconButton onClick={handleClickOpen} size="small">
          <SendIcon style={{ color: green[500] }} />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Start Automation for {eventName}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          <DialogContentText>
            Select the execution mode and specify the number of threads to run in parallel.
          </DialogContentText>

          <Grid container alignItems='center' spacing={3}>
            {/* <Grid item>
        <FormControl >
        <InputLabel id="demo-simple-select-label">Execution Mode</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="execution-mode"
          value={mode}
          onChange={(e) => setMode(e.target.value)}
        >
          <MenuItem value={0}>Run Slot Wise</MenuItem>
          <MenuItem value={1}>Run Failed Records</MenuItem>
          
        </Select>
      </FormControl>
        </Grid> */}
            <Grid item>   <TextField
              autoFocus
              margin="dense"
              id="name"
              label="No Of Threads"

              value={noOfThreads}
              onChange={(e) => setNoOfThreads(e.target.value)}
              type="number"
            /></Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={startAutomation}
            color="primary"
            disabled={loading}
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

StartAutomationComponent.propTypes = {
  eventId: PropTypes.any.isRequired,
  eventName: PropTypes.any.isRequired,
  variant: PropTypes.any.isRequired,
};

StartAutomationComponent.defaultProps = {
  variant: "large",
};
