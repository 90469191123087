import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  AppBar,
} from "@material-ui/core";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import UploadAccount from "../components/UploadAccount";
import TabPanel from "../components/TabPanel";
import AssignTags from "../components/AssignTags";
import AccountColumns from "../utils/accounts_grid_column";
import { red } from "@material-ui/core/colors";
import { DeleteOutline } from "@material-ui/icons";

export default function AccountsContainer() {
  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountsExportData, setAccountsExportData] = useState([]);
  const [message, setMessage] = useState();
  const exportAccountLink = useRef();

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccounts = () => {
    setLoading(true);
    axios.get("/accounts").then((response) => {
      setAccounts(response.data);
      let data = [];
      response.data.forEach((account) => {
        data.push({
          ...account,
          tag: account.tag ? account.tag.name : "",
        });
      });
      console.log(data);
      setAccountsExportData(data);

      setLoading(false);
    });
  };

  const deleteAccounts = () => {
    if (window.confirm("Are you sure you want to delete all the accounts ?")) {
      setLoading(true);
      axios.delete(`/accounts/`).then((response) => {
        setLoading(false);
        setMessage({ type: "success", text: response.data.message });
        getAccounts();
      });
    }
  };

  const [selectedTab, setSelectedTab] = React.useState(0);
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Typography variant="h3">Accounts</Typography>
        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}

<UploadAccount
            setLoading={setLoading}
            setMessage={setMessage}
            getAccounts={getAccounts}
          />

       
        <Box pt={2}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => {
                  exportAccountLink.current.link.click();
                }}
              >
                Export
              </Button>
              <CSVLink
                data={accountsExportData}
                filename={`tm_vf_accounts_${moment().format(
                  "YYYYMMDD_hmmss"
                )}.csv`}
                ref={exportAccountLink}
              />
            </Grid>
            <Grid item>
            <Button
                variant="outlined"
                style={{
                  color:red[500],
                  borderColor:red[500],
              }}
                
                startIcon={<DeleteOutline />}
                onClick={deleteAccounts}
              >
                Delete Accounts
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box pt={2}>
          <DataGrid
            rows={accounts}
            columns={AccountColumns}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </Layout>
    </React.Fragment>
  );
}
