import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import axios from "axios";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVReader } from "react-papaparse";
import PublishIcon from "@material-ui/icons/Publish";
import PropTypes from "prop-types";

export default function UploadAccount(props) {
  const { setLoading, setMessage, getAccounts } = props;
  const [tags, setTags] = useState([]);
  const [accountsUploadData, setAccountsUploadData] = useState([]);
  const exportTemplateLink = useRef();
  const [selectedTag, setSelectedTag] = useState(0);
  useEffect(() => {
    axios.get("/tags").then((response) => {
      setTags(response.data);
    });
  }, []);

  const export_headers = [
    { label: "email", key: "email" },
    { label: "password", key: "password" },
    { label: "first_name", key: "first_name" },
    { label: "last_name", key: "last_name" },
    { label: "zip_code", key: "zip_code" },
    { label: "phone_number", key: "phone_number" },
    { label: "proxy", key: "proxy" },
    { label: "city", key: "city" },
    { label: "state", key: "state" },
      { label: "additional_field_1", key: "additional_field_1" },
        { label: "additional_field_2", key: "additional_field_2" },
          { label: "additional_field_3", key: "additional_field_3" },
  ];

  const handleOnDrop = (records) => {
    let accounts = [];
    for (let record of records) {
      accounts.push({ ...record.data });
    }
    setAccountsUploadData(accounts);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const upload = () => {
    let data = [];
    for (let accountToUpload of accountsUploadData) {
      data.push({ ...accountToUpload,address_1:accountToUpload.proxy,tag_id: selectedTag });
    }
    setLoading(true);
    console.log(data);
    axios
      .post("/account/bulk-upload", data)
      .then((response) => {
        setMessage({ type: "success", text: response.data });
        getAccounts();
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.statusText });
        setLoading(false);
      });
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              config={{ header: true, skipEmptyLines: true }}
              style={{
                dropArea: {
                  padding: "15px",
                },
                removeButton: {
                  color: "red",
                },
              }}
            >
              <span>Drop Account Upload CSV file here or click to upload.</span>
            </CSVReader>
          </Grid>
         
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PublishIcon />}
              onClick={upload}
              disabled={accountsUploadData.length === 0}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => {
                exportTemplateLink.current.link.click();
              }}
            >
              Bulk Upload Template
            </Button>
            <CSVLink
              data={[]}
              headers={export_headers}
              filename={`vf_account_upload_template.csv`}
              ref={exportTemplateLink}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

UploadAccount.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
};
