import React from "react";
import Header from "../components/Header";
import Container from "@material-ui/core/Container";

export default function Layout(props) {
  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="xl">{props.children} </Container>
    </React.Fragment>
  );
}
