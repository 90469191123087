import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import Loader from "../components/Loader";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Tooltip,
  IconButton,
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export default function TagsContainer() {
  const [tagsData, setTagsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [tagName, setTagName] = useState();
  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    setLoading(true);
    axios.get("/tags").then((response) => {
      setTagsData(response.data);
      setLoading(false);
    });
  };
  const createTag = () => {
    setLoading(true);
    axios.post("/tag/", { name: tagName }).then((response) => {
      setLoading(false);
      setMessage({ type: "success", text: "Tag Created Successfully." });
      getTags();
    });
  };

  const deleteTag = (id) => {
    setLoading(true);
    axios.delete(`/tag/${id}`).then((response) => {
      setLoading(false);
      setMessage({ type: "success", text: "Tag Deleted Successfully." });
      getTags();
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2} pb={3}>
          <Typography variant="h3">Tags</Typography>
        </Box>

        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}
        <Box pb={3}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={4}
            alignItems="center"
          >
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                id="tag_name"
                label="Tag Name"
                variant="outlined"
                fullWidth
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                size="small"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={createTag}
                disabled={tagName === undefined || tagName === ""}
              >
                Create Tag
              </Button>
            </Grid>
          </Grid>
        </Box>

        {tagsData.map((tag) => (
          <Box pt={2}>
          <Paper elevation={3}>
            <Box p={2} key={tag.id}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item lg={2} xl={2}>
                  <Typography variant="h5"> {tag.name} </Typography>
                </Grid>
                <Grid item>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Tooltip title="Created Date & Time">
                        <AccessTimeIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography variant="body">
                        {moment.utc(tag.created_at).local().format("LLL")}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Tooltip title="Delete Tag">
                    <IconButton
                      onClick={() => {
                        deleteTag(tag.id);
                      }}
                      size="small"
                    >
                      <DeleteIcon style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          </Box>
        ))}

        {tagsData.length === 0 && (
          <Box pt={2}>
            <Typography variant="h5" align="center">
              No tags.
            </Typography>
          </Box>
        )}
      </Layout>
    </React.Fragment>
  );
}
