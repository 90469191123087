import "./App.css";
import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import LoginContainer from "./containers/LoginContainer";
import EventsContainer from "./containers/EventsContainer";
import AccountsContainer from "./containers/AccountsContainer";
import EventStatusContainer from "./containers/EventStatusContainer";
import ProfileContainer from "./containers/ProfileContainer";
import TagsContainer from "./containers/TagsContainer";
import EventSelectAccountContainer from "./containers/EventSelectAccountContainer";
import NotificationsContainer from "./containers/NotificationContainer";
import AssignEventsContainer from "./containers/AssignEventsContainer";
const theme = createTheme({
  palette: {
    primary: {
      main: "#263238",
    },
    secondary: {
      main:  "#009688",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LoginContainer} />
          <Route path="/events" component={EventsContainer} />
          <Route path="/accounts" component={AccountsContainer} />
          <Route
            path="/event/:event_id/status"
            component={EventStatusContainer}
          />
          <Route path="/profile" component={ProfileContainer} />
          <Route path="/tags" component={TagsContainer} />
          <Route
            path="/event/:event_id/select-accounts"
            component={EventSelectAccountContainer}
          />
           <Route
            path="/event/:event_id/assign-events"
            component={AssignEventsContainer}
          />
          <Route path="/notifications" component={NotificationsContainer} />
          
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
